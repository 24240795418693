import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isAdmin, notExpired } from "../helpers/authentication";

function IsAdmin({ children }: any) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const navbar = document.getElementById("navbar");
    const footer = document.getElementById("footer");
    if (location.pathname.split("/").includes("admin")) {
      if (navbar && footer) {
        navbar.style.display = "none";
        footer.style.display = "none";
      }

      const auth = localStorage.getItem("auth");
      const decodedAuth = JSON.parse(auth!);

      if (auth !== null) {
        if (isAdmin(decodedAuth.role) !== true) {
          navigate("/");
        }
        if (notExpired(decodedAuth.token) !== false) {
          navigate("/admin");
          logoutUser();
        }
      } else {
        navigate("/admin");
      }
    } else {
      if (navbar) {
        navbar.style.display = "flex";
      }
      if (footer) {
        footer.style.display = "flex";
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const logoutUser = () => {
    localStorage.removeItem("auth");
  };

  return <>{children}</>;
}

export default IsAdmin;
