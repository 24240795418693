import { useState } from "react";

const useMainContext = () => {
  const [homepage, setHomepage] = useState({});

  const handleLogout = () => {
    localStorage.clear();
  };

  return {
    homepage,
    setHomepage,
    handleLogout,
  };
};

export default useMainContext;
