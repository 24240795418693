// main-context.tsx
import { createContext } from "react";

interface MainContextProps {
  homepage: any;
  handleLogout: (error?: string) => void;
  setHomepage: React.Dispatch<React.SetStateAction<any>>;
}

// Create a context with a default value
export const MainContext = createContext<MainContextProps>({
  homepage: {},
  setHomepage: () => {},
  handleLogout: () => {},
});
