import { jwtDecode } from "jwt-decode";
export const isAdmin = (role: string) => {
  if (role !== "admin") {
    return false;
  }
  return true;
};

export const notExpired = (token: string) => {
  const decodedToken = jwtDecode(token);
  const now = Date.now() / 1000;
  if (decodedToken.exp !== undefined && decodedToken.exp < now) {
    return true;
  }
  return false;
};
