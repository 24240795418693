import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./intl/i18n";
import "animate.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/de";
import "moment/locale/fr";

import { I18nextProvider } from "react-i18next";
import i18n from "./intl/i18n";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <I18nextProvider i18n={i18n}>
    <App />
    <ToastContainer />
  </I18nextProvider>
);
