import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Custom language mapping function
const mapLanguage = (lng: string): string => {
  const mappings: { [key: string]: string } = {
    "en-US": "en",
    "fr-CA": "fr",
    "es-MX": "es",
    "it-IT": "it",
    "de-DE": "de",
    en: "en",
    fr: "fr",
    es: "es",
    it: "it",
    de: "de",
  };

  return mappings[lng] || lng.split("-")[0]; // Fallback to base language if specific mapping is not found
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "de",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["cookie"],
    },
    supportedLngs: ["de", "fr", "es", "it", "en"], // List of supported languages
    load: "languageOnly", // Load only the base language, ignoring the region code
  });

// Manually set the language using the custom mapping function
const detectedLng =
  i18n.language ||
  window.navigator.language ||
  localStorage.getItem("lang")! ||
  "de";
const mappedLng = mapLanguage(detectedLng);
i18n.changeLanguage(mappedLng);

export default i18n;
